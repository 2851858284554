<template>
  <v-row align="center" justify="center">
    <v-col cols="12">
      <v-card elevation="0" tile>
        <v-card-title class="grey lighten-4">
          {{ $t("hr.note.notes") }}
          <v-spacer />

          <id-button class="mr-2" @click="onCreate">
            <template #icon>
              <v-icon left>mdi-plus</v-icon>
            </template>
            {{ $t("hr.note.add_note") }}
          </id-button>
          <id-button @click="onRefreshData">
            <template #icon>
              <v-icon left>mdi-refresh</v-icon>
            </template>
            {{ $t("buttons.refresh") }}
          </id-button>
        </v-card-title>
        <v-data-table
          :headers="table.headers"
          :items="table.data"
          :options.sync="table.options"
          :server-items-length="table.totalItems"
          single-select
          class="elevation-1"
          :loading="table.loading"
          :footer-props="{ 'items-per-page-options': [10, 25, 50, 100] }"
          single-expand
        >
          <template #item="row">
            <tr>
              <td class="truncate">{{ row.item.title }}</td>
              <td>
                <employee-profile :employee="row.item.owner" />
              </td>

              <td>{{ $t(`global.importance_enum.${row.item.importance}`) }}</td>
              <td class="text-center">
                <file-download-button :url="row.item.url" />
              </td>
              <td class="text-right">
                <v-btn icon @click.stop.prevent="row.expand(!row.isExpanded)">
                  <v-icon v-if="row.isExpanded">mdi-chevron-up</v-icon>
                  <v-icon v-else>mdi-chevron-down</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
          <template #expanded-item="{ headers, item }">
            <tr>
              <td :colspan="headers.length" class="pa-0">
                <v-card flat class="grey lighten-4">
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" sm="2">
                        <strong>{{ $t("hr.note.title") }}</strong>
                      </v-col>
                      <v-col cols="12" sm="10">
                        {{ item.title }}
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="2">
                        <strong>{{ $t("hr.note.body") }}</strong>
                      </v-col>
                      <v-col cols="12" sm="10">
                        {{ item.body }}
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="2">
                        <strong>{{ $t("hr.note.description") }}</strong>
                      </v-col>
                      <v-col cols="12" sm="10">
                        {{ item.description }}
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </td>
            </tr>
          </template>
        </v-data-table>
        <transition name="slide-in-left">
          <router-view @success:save="onRefreshData" />
        </transition>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
  /**
   * @author Ali Hüseyin ŞİRELİ
   * @email ali.sireli@globme.com.tr
   * @create date 2020-12-31 15:55:14
   * @modify date 2020-12-31 15:55:14
   * @desc Notes
   */
  import { NOTES } from "./query";

  export default {
    name: "Notes",
    data: vm => ({
      table: {
        loading: false,
        data: [],
        criteria: {},
        options: {},
        pagination: {
          rowsPerPage: 50,
          sortBy: "createdDateTime",
          descending: true
        },
        totalItems: 0,
        totalPages: 0,
        selected: [],
        headers: [
          {
            text: vm.$t("hr.note.title"),
            value: "title",
            align: "start",
            sortable: true,
            class: ["primary--text"],
            width: "20%"
          },
          {
            text: vm.$t("hr.note.owner"),
            value: "owner.firstName",
            align: "start",
            sortable: true,
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.note.importance"),
            value: "importance",
            align: "start",
            sortable: true,
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.note.attachment"),
            align: "center",
            sortable: false,
            class: ["primary--text"]
          },
          {
            align: "end"
          }
        ]
      }
    }),
    watch: {
      "table.options": {
        handler(options) {
          let { itemsPerPage, page } = options;
          this.table.pagination.rowsPerPage = itemsPerPage;
          this.table.pagination.page = page;
          this.fetchItems();
        },
        deep: true
      }
    },
    methods: {
      onRefreshData() {
        this.fetchItems();
      },
      async fetchItems() {
        this.table.loading = true;
        await this.$apollo
          .query({
            query: NOTES,
            variables: {
              criteria: this.table.criteria,
              pagination: this.$helpers.convertPagination(this.table.pagination)
            }
          })
          .then(({ data, errors }) => {
            if (!errors || !data.error) {
              this.table.data = data.filterWithPaginateNotes.content || [];
              this.table.totalItems = data.filterWithPaginateNotes.pageInfo.totalElements || 0;
            }
          })
          .catch(e => {
            this.$helpers.showNotification(e.message);
          })
          .finally(() => (this.table.loading = false));
      },
      onCreate() {
        this.$router.replace({ name: "note_create" });
      }
    }
  };
</script>

<style scoped>
  .truncate {
    max-width: 1px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
